import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import * as auth from '../../app/modules/auth'
import {OrganizationsReducer} from '../../app/modules/organizations/redux/OrganizationsRedux'

export const rootReducer = combineReducers({
  authStore: auth.reducer,
  organizationsStore: OrganizationsReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type OrganizationReducer = ReturnType<typeof OrganizationsReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
