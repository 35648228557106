import * as Yup from 'yup'
import { OrganizationModel } from '../../../../_models/OrganizationModel'
import { Plan, TeamSize } from '../../../../_models/OrganizationEnums'

const createAccountSchemas = [
  Yup.object({
    name: Yup.string().required().label('Organization Name'),
    teamSize: Yup.string().required().label('Specify Team Size'),
    plan: Yup.string().required().label('Select a Plan')
  }),
  Yup.object({
    nameOnCard: Yup.string().required().label('Name On Card'),
    cardNumber: Yup.string().required().label('Card Number'),
    cardExpiryMonth: Yup.string().required().label('Expiration Month'),
    cardExpiryYear: Yup.string().required().label('Expiration Year'),
    cardCvv: Yup.string().required().label('CVV'),
  }),
]

const inits: OrganizationModel = {
  plan: Plan.free,
  teamSize: TeamSize.single,
  name: '',
  payment: {
    nameOnCard: '',
    cardNumber: '',
    cardExpiryMonth: '1',
    cardExpiryYear: '',
    cardCvv: '',
    saveCard: '1',
  }
}

export {createAccountSchemas, inits}
