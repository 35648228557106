/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Dropdown1} from '../../../../../_metronic/partials'

const OrganizationAddCollaborator: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Let your team know!</h2>

        <div style={{height: '200px', textAlign: 'center'}}>
          <img
            style={{height: '100%'}}
            alt='Pic'
            src={toAbsoluteUrl('/media/grafics/lonely.png')}
          />
        </div>

        <div className='text-gray-500 fw-bold fs-6' style={{textAlign: 'center'}}>
          Your Organization looks a bit lonely, invite some friends.
        </div>

        <br />

        <div className='card-header' id='kt_chat_messenger_header' style={{textAlign: 'center'}}>
          <div className='card-title'>
            <div className='symbol-group symbol-hover'>
              <div className='symbol symbol-50px symbol-circle'>
                <span className='symbol-label bg-light-danger text-danger 40px'>YOU</span>
              </div>

              <a
                href='#'
                className='symbol symbol-50px symbol-circle'
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_view_users'
              >
                <span
                  className='symbol-label fs-20 fw-bolder'
                  data-bs-toggle='tooltip'
                  data-bs-trigger='hover'
                  title='View more users'
                >
                  +
                </span>
              </a>
              <a
                href='#'
                className='symbol symbol-50px symbol-circle'
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_view_users'
              >
                <span
                  className='symbol-label fs-20 fw-bolder'
                  data-bs-toggle='tooltip'
                  data-bs-trigger='hover'
                  title='View more users'
                >
                  +
                </span>
              </a>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='me-n3'>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <i className='bi bi-three-dots fs-2'></i>
              </button>
              <Dropdown1 />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {OrganizationAddCollaborator}
