import ky from 'ky'
import {getNewToken} from '../setup/firebase'
import store from '../setup/redux/Store'
import {parseJwt} from '../util'
import * as auth from '../app/modules/auth/redux/AuthRedux'

const BASE_API_URL = process.env.REACT_APP_API_URL || 'api'

const BackendReq = ky.extend({
  prefixUrl: BASE_API_URL,
  retry: {
    limit: 3,
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        const {
          authStore: {accessToken},
        } = store.getState()

        // const newToken = getNewToken()
        if (accessToken) {
          let token = accessToken
          const decoded = parseJwt(accessToken)
          const current = (new Date().getTime() + 1) / 1000
          const isTokenExpired = decoded.exp < current

          if (isTokenExpired) {
            token = (await getNewToken()) || ''
            store.dispatch(auth.actions.login(token))
          }
          request.headers.set('Authorization', `Bearer ${token}`)
        }
      },
    ],
  },
})

export {BackendReq}
