/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import { TeamSize } from '../../../../../_models/OrganizationEnums'

const OrganizationInformation: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Basic Information</h2>

        <div className='text-gray-500 fw-bold fs-6'>
          If you need more info about our plans, please check out the
          <a href='#' className='link-primary fw-bolder'>
            {' '}
            Plans and Pricing Page
          </a>
          .
        </div>
      </div>


      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Organization Name</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='name'
          placeholder=''
        />
       
        <div className='fv-plugins-message-container invalid-feedback'>
          <ErrorMessage name='name' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Specify Team Size
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Provide your team size to help us setup your billing'
          ></i>
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='teamSize'
              value={TeamSize.single}
              id='kt_account_team_size_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_1'
            >
              <span className='fw-bolder fs-3'>{TeamSize.single}</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='teamSize'
              value={TeamSize.small}
              id='kt_account_team_size_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_2'
            >
              <span className='fw-bolder fs-3'>{TeamSize.small}</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='teamSize'
              value={TeamSize.medium}
              id='kt_account_team_size_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_3'
            >
              <span className='fw-bolder fs-3'>{TeamSize.medium}</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='teamSize'
              value={TeamSize.large}
              id='kt_account_team_size_select_4'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_4'
            >
              <span className='fw-bolder fs-3'>{TeamSize.large}</span>
            </label>
          </div>
        </div>

      </div>

    

      <div className='mb-0 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Select a Plan
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Monthly billing will be based on your account plan'
          ></i>
        </label>

        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTSVG
                    path='/media/icons/duotone/Interface/Line-03-Down.svg'
                    className='svg-icon-1 svg-icon-gray-600'
                  />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Free Plan{' '}
                  <span className='fw-bolder text-gray-500' style={{float: 'right'}}>
                    [$0/month]
                  </span>
                </span>
                <span className='fs-6 fw-bold text-gray-500'>
                  Quarterly reports and limited access to analytics (but free)
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='plan' value='free' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTSVG
                    path='/media/icons/duotone/Interface/Doughnut.svg'
                    className='svg-icon-1 svg-icon-gray-600'
                  />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Company Plan{' '}
                  <span className='fw-bolder text-gray-500' style={{float: 'right'}}>
                    [$10/month]
                  </span>
                </span>
                <span className='fs-6 fw-bold text-gray-500'>
                  Full access to reports and analytics for a single company
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='plan' value='company' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-0 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTSVG
                    path='/media/icons/duotone/Interface/Bank.svg'
                    className='svg-icon-1 svg-icon-gray-600'
                  />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Broker Plan{' '}
                  <span className='fw-bolder text-gray-500' style={{float: 'right'}}>
                    [$7/month per company]
                  </span>
                </span>
                <span className='fs-6 fw-bold text-gray-500'>
                  Reports and Analytics for multiple companies. Discounted price per company, minimum 2
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='plan' value='broker' />
            </span>
          </label>
        </div>
      </div>
    </div>
  )
}

export {OrganizationInformation}
