import {OrganizationModel} from './../_models/OrganizationModel'
import {BackendReq} from './BackendReq'

export const Organizations = (() => {
  /**
   *
   */
  const create = async (organization: OrganizationModel) => {
    return await BackendReq.post(`organizations`, {
      json: organization,
    }).json<OrganizationModel>()
  }

  return Object.freeze({
    create,
  })
})()
