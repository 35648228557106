import { OrganizationModel } from './../_models/OrganizationModel';
import {UserModel} from '../_models/UserModel'
import {BackendReq} from './BackendReq'

export const Users = (() => {
  /**
   *
   */
  const me = async () => {
    return await BackendReq.get(`auth/me`).json<UserModel>()
  }

  const organizations = async (userId: string) => {
    return await BackendReq.get(`users/${userId}/organizations`).json<OrganizationModel[]>()
  }

  return Object.freeze({
    me,
    organizations
  })
})()
