import {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import { RootState } from '../../../setup'
import {LayoutSplashScreen} from '../../../_metronic/layout/core'
import * as auth from './redux/AuthRedux'

export function Logout() {
  const accessToken = useSelector<RootState>(({authStore}) => authStore.accessToken, shallowEqual)
  console.log('LOGGING OUT')

  const dispatch = useDispatch()

  useEffect(() => {
    if(accessToken) {
      dispatch(auth.actions.googleLogOut())
    }
  }, [dispatch, accessToken])

  if (accessToken) {
    return <LayoutSplashScreen />
  }

  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}
