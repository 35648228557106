export enum Plan {
    free = 'free',
    company = 'company',
    broker = 'broker'
}

export enum TeamSize {
    single = '1-1',
    small = '2-10',
    medium = '10-50',
    large = '50+'
}