import {initializeApp} from 'firebase/app'
import {
  signInWithPopup,
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  UserCredential,
  sendEmailVerification
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyDjQF9xtyLZnJen-9oR0NGmt9S2c1DLB3Q',
  authDomain: 'goatfin-ea33b.firebaseapp.com',
  projectId: 'goatfin-ea33b',
  storageBucket: 'goatfin-ea33b.appspot.com',
  messagingSenderId: '632368703943',
  appId: '1:632368703943:web:88ad3c49d9fa364aee11e2',
  measurementId: 'G-R04N2MPJLZ',
}

initializeApp(firebaseConfig)
const googleProvider = new GoogleAuthProvider()
export const auth = getAuth()

export const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider)
  } catch (error) {
    console.log(error)
  }
}

export const signInWithPassword = async ({email, password}: {email: string; password: string}) => {
  const userCredentials = await signInWithEmailAndPassword(auth, email, password)
  if(!userCredentials.user.emailVerified) {
    sendEmailVerification(userCredentials.user)
  }
  return userCredentials
}

export const registerWithEmailAndPassword = async ({
  email,
  password,
}: {
  email: string
  password: string
}) : Promise<UserCredential> => {
  const userCredentials = await createUserWithEmailAndPassword(auth, email, password)
  sendEmailVerification(userCredentials.user)
  return userCredentials
}

export const passwordResetEmail = async (email: string) => {
  await sendPasswordResetEmail(auth, email)
}

export const logout = async (): Promise<void> => {
  return await auth.signOut()
}

export const getNewToken = async (): Promise<string | undefined> => {
    return await auth.currentUser?.getIdToken(true)
}
