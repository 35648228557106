import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {RootState} from '../../../../setup'
import {Users} from '../../../../api/Users'
import {OrganizationActions} from '../../organizations/redux/OrganizationsRedux'
import {parseJwt} from '../../../../util'

const mapState = (state: RootState) => ({
  auth: state.authStore,
  organizations: state.organizationsStore,
})
const connector = connect(mapState, {...auth.actions, ...OrganizationActions})
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({authStore}) => authStore.accessToken, shallowEqual)

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          setShowSplashScreen(true)
          const decoded = parseJwt(accessToken as string)
          const [user, organizations] = await Promise.all([
            Users.me(),
            Users.organizations(decoded.user_id),
          ])
          dispatch(props.setOrganizations(organizations))
          dispatch(props.fulfillUser(user))
        }
      } catch (error) {
        console.log(error)
        if (!didRequest.current) {
          dispatch(props.googleLogOut())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      // dispatch(props.googleLogOut())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [accessToken])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
