import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {ROOT_STORE_NAME} from '../../../../setup/redux/const'
import {OrganizationModel} from '../../../../_models/OrganizationModel'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setOrganizations: '[Set Organizations] Action',
}

export interface OrganizationState {
  organizations: {
    belongsTo?: OrganizationModel[]
  }
}

const initialOrganizationsState: OrganizationState = {
  organizations: {
    belongsTo: undefined,
  },
}

export const OrganizationsReducer = persistReducer(
  {storage, key: ROOT_STORE_NAME, whitelist: ['organizations']},
  (
    state: OrganizationState = initialOrganizationsState,
    action: ActionWithPayload<OrganizationState>
  ) => {
    switch (action.type) {
      case actionTypes.setOrganizations: {
        return {
          ...state,
          ...{
            organizations: {
              belongsTo: action.payload?.organizations?.belongsTo,
            },
          },
        }
      }

      default:
        return state
    }
  }
)

export const OrganizationActions = {
  setOrganizations: (belongsTo: OrganizationModel[]) => ({
    type: actionTypes.setOrganizations,
    payload: {
      organizations: {
        belongsTo,
      },
    },
  }),
}

export function* saga() {}
