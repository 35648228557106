import Lottie from 'react-lottie'
import * as animationData from './loading.json'

export function FallbackView() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div className='splash-screen'>
      <Lottie options={defaultOptions} height={400} width={400} />
      <span>Loading ...</span>
    </div>
  )
}
