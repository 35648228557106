import {FC, useEffect, useRef, useState} from 'react'
import {OrganizationInformation} from './steps/OrganizationInformation'
import {KTSVG} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {createAccountSchemas, inits} from '../../wizards/components/CreateAccountWizardHelper'
import {OrganizationBilling} from './steps/OrganizationBilling'
import {OrganizationAddCollaborator} from './steps/OrganizationAddCollaborator'
import {Organizations} from '../../../../api/Organizations'
import {OrganizationModel} from '../../../../_models/OrganizationModel'

const CreateOrganizationWizard: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const formikRef = useRef<any>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<OrganizationModel>(inits)
  const btnRef = useRef<HTMLButtonElement | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex === 3 && formikRef?.current?.values['plan'] === 'free') {
      stepper.current.goto(1)
    } else {
      stepper.current.goPrev()
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const loadingOn = () => {
    btnRef.current?.setAttribute('data-kt-indicator', 'on')
  }

  const loadingOff = () => {
    btnRef.current?.removeAttribute('data-kt-indicator')
  }

  const submitStep = async (values: OrganizationModel, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    loadingOn()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex === 1 && values.plan === 'free') {
      // Create free organization
      const org = await Organizations.create(values).finally(() => {
        loadingOff()
      })
      console.log(org)

      stepper.current.goto(3)
      
    } else if (stepper.current.currentStepIndex === 2 && values.plan !== 'free') {
      // Check credit card
      // Create paid organization

      console.log('SHOULD CREATE PAID ORG, now')

      stepper.current.goto(3)
    } else if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }

    loadingOff()
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Basic Information</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Billing Details</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Add Collaborators</h3>
            </div>
          </div>

          <Formik
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={submitStep}
            innerRef={formikRef}
          >
            {() => (
              <Form
                className='mx-auto mw-600px w-100 pt-15 pb-10'
                noValidate
                id='kt_create_account_form'
              >
                <div className='current' data-kt-stepper-element='content'>
                  <OrganizationInformation />
                </div>

                <div data-kt-stepper-element='content'>
                  <OrganizationBilling />
                </div>

                <div data-kt-stepper-element='content'>
                  <OrganizationAddCollaborator />
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Left-2.svg'
                        className='svg-icon-4 me-1'
                      />
                      Back
                    </button>
                  </div>

                  <div>
                    <button ref={btnRef} type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-progress'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                      <span className='indicator-label'>
                        {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 1
                          ? 'Submit'
                          : 'Continue'}
                        <KTSVG
                          path='/media/icons/duotone/Navigation/Right-2.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {CreateOrganizationWizard}
